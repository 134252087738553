.ToDoSearch {
    width: 50%;
    height: auto;
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
    margin: 50px auto;
    padding: 20px 50px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    form {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
            padding: 10px 25px;
            border-radius: 25px;
            width: 90%;
            height: 50px;
            border: none;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }

        button {
            background-color: #8ac4eb;
            margin-left: 10px;
            padding: 15px 25px;
            text-align: center;
            font-weight: 900;
            font-size: 16px;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;      
            border-radius: 20px;
            display: block;
            border: none;
            cursor: pointer;
            box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;


            &:hover {
                background-color: #63a8d7;
            }
        }
    }
}